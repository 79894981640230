<template>
  <cart-container :title="$t('createOrder.comment')">
    <text-input placeholder="..." :value="value" @input="$emit('input', $event)" />
  </cart-container>
</template>

<script>
import Vue from 'vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import CartContainer from './CartContainer.vue'

export default Vue.extend({
  name: 'CartComment',
  components: { CartContainer, TextInput },
  props: { value: String },
})
</script>

<style lang="scss" module></style>
