<template>
  <v-form v-model="isValid" :class="$style.form" :is-loading="pageLoading" @submit="onSubmit">
    <cart-products :full-price="fullPrice" />
    <!-- <create-order-wrapping :currency="currency" :price="prices.wrapping" /> -->
    <cart-comment v-model="note" />
    <cart-promo v-if="isDelivery" :price="prices.promo" />
    <cart-delivery v-model="recipient" :is-delivery="isDelivery" :price="prices.promo" />
    <cart-checkout
      :is-valid="isValid"
      :full-price="fullPrice"
      :is-delivery="isDelivery"
      :prices="prices"
    />
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VForm from '@elements/VForm.vue'

import CartProducts from './list/CartProducts.vue'
import CartComment from './list/CartComment.vue'
import CartPromo from './list/CartPromo.vue'
import CartDelivery from './list/CartDelivery.vue'
import CartCheckout from './list/CartCheckout.vue'
// import CreateOrderWrapping from './list/CreateOrderWrapping.vue'

export default Vue.extend({
  name: 'Cart',
  components: {
    VForm,
    CartProducts,
    CartComment,
    CartPromo,
    CartDelivery,
    CartCheckout,
    // CreateOrderWrapping,
  },
  data() {
    return {
      note: '',
      recipient: {
        address: '',
        country_id: null,
        name: '',
        patronymic: '',
        phone: '',
        surname: '',
        email: '',
      },
      prices: {
        delivery: 0,
        promo: 0,
        wrapping: 0,
      },
      isValid: false,
      isCreated: false,
    }
  },
  computed: {
    ...mapGetters('App', ['appId']),
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Cart', ['cartProducts', 'cartProductsLength']),
    ...mapGetters('Page', ['pageLoading']),
    cartServices() {
      return this.cartProducts.filter(({ type }) => type === 2)
    },
    isDelivery() {
      return this.cartServices.length !== this.cartProductsLength
    },
    orderPositions() {
      return this.cartProducts.map(({ id, count }) => {
        return { product_id: id, count }
      })
    },
    fullPrice() {
      return this.cartProducts.reduce((acc, { price, count }) => {
        acc += price * count
        return +acc.toFixed(3)
      }, 0)
    },
  },
  methods: {
    ...mapMutations('Cart', ['resetCartProducts']),
    ...mapActions('Cart', ['postCart']),
    ...mapMutations('Page', ['setPageTitle', 'setPageIsFooter']),
    async onSubmit() {
      const order = await this.postCart({
        app_id: this.appId(),
        delivery_method: 1,
        note: this.note,
        order_positions: this.orderPositions,
        recipient: this.recipient,
        type: 1,
      })
      if (order.id) {
        this.isCreated = true
        this.resetCartProducts()
        this.$router.push({ name: 'Orders', params: { id: order.id, locale: this.locale } })
      }
    },
  },
  watch: {
    cartProductsLength(length) {
      if (!length && !this.isCreated) {
        this.$router.go(-1)
      }
    },
  },
  mounted() {
    if (!this.cartProductsLength) {
      this.$router.go(-1)
    }
    this.setPageIsFooter(false)
    this.setPageTitle({ name: 'orders.checkout' })
  },
  destroyed() {
    this.setPageIsFooter(true)
  },
})
</script>

<style lang="scss" module>
.form {
  row-gap: 0;
}
</style>
