// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartCheckout_item_2Y7es{align-items:center;justify-content:space-between;padding-top:15px;font-weight:500;font-size:12px;line-height:12px;border-top:1px solid var(--supster-secondary)}.CartCheckout_button_3ZQIo,.CartCheckout_item_2Y7es{display:grid;grid-auto-flow:column}.CartCheckout_button_3ZQIo{justify-content:center}.CartCheckout_buttonIcon_1aGlS{width:14px;height:14px;margin-right:10px}.CartCheckout_buttonIcon_1aGlS,.CartCheckout_text_wlgRh{color:var(--supster-secondary)}.CartCheckout_text_wlgRh{font-weight:400;font-size:12px;line-height:18px;text-align:center}.CartCheckout_text_wlgRh.CartCheckout_link_2XXJs{font-weight:500;transition:opacity .3s}.CartCheckout_text_wlgRh.CartCheckout_link_2XXJs:hover{opacity:.5}", ""]);
// Exports
exports.locals = {
	"item": "CartCheckout_item_2Y7es",
	"button": "CartCheckout_button_3ZQIo",
	"buttonIcon": "CartCheckout_buttonIcon_1aGlS",
	"text": "CartCheckout_text_wlgRh",
	"link": "CartCheckout_link_2XXJs"
};
module.exports = exports;
