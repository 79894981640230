<template>
  <CartContainer :title="`${$t('createOrder.promo')}`" :price="price" sign="-">
    <div :class="$style.section">
      <TextInput placeholder="..." v-model="promo" />
      <VButton @click="onPromo">{{ $t('createOrder.activate') }}</VButton>
    </div>
  </CartContainer>
</template>

<script>
import Vue from 'vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import VButton from '@elements/VButton.vue'
import CartContainer from './CartContainer.vue'

export default Vue.extend({
  name: 'CartPromo',
  components: { CartContainer, VButton, TextInput },
  props: { price: Number },
  data() {
    return {
      promo: '',
    }
  },
  methods: {
    onPromo() {
      this.$notify({
        type: 'error',
        title: `${this.$t('errors.title')}`,
        text: `${this.$t('errors.promo.description')}`,
      })
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
}
</style>
