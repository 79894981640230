<template>
  <cart-container
    :title="isDelivery ? $t('delivery') : $t('receiver')"
    :price="isDelivery ? deliveryPrice : null"
    :sign="isDelivery ? '+' : null"
  >
    <select-dropdown
      v-if="isDelivery"
      name="countries"
      icon-size="sm"
      :select-value="value.country_id"
      :placeholder="$t('choose')"
      :title="$t('country')"
      :list="countries"
      :rules="rules.country"
      :is-loading="pageLoading"
      is-search
      @change="$emit('input', { ...value, country_id: $event.id })"
    >
      <template #no-data> {{ $t('no.countries') }} </template>
    </select-dropdown>
    <div v-if="isDelivery">
      <p :class="$style.title">{{ $t('createOrder.delivery') }}</p>
      <v-tabs :tabs="tabs" color="default" size="sm" />
    </div>
    <text-input
      :value="value.email"
      @input="$emit('input', { ...value, email: $event })"
      :rules="rules.email"
      :title="$t('email')"
      :placeholder="$t('enter.email')"
    />
    <text-input
      v-if="isDelivery"
      :value="value.address"
      @input="$emit('input', { ...value, address: $event })"
      :rules="rules.address"
      :title="$t('address')"
      :placeholder="$t('enter.address')"
    />
    <text-input
      :value="value.name"
      @input="$emit('input', { ...value, name: $event })"
      :rules="rules.name"
      :title="$t('name')"
      :placeholder="$t('enter.name')"
    />
    <text-input
      :value="value.surname"
      @input="$emit('input', { ...value, surname: $event })"
      :rules="rules.surname"
      :title="$t('surname')"
      :placeholder="$t('enter.surname')"
    />
    <text-input
      v-if="locale === 'ru'"
      :value="value.patronymic"
      @input="$emit('input', { ...value, patronymic: $event })"
      :rules="rules.patronymic"
      :title="$t('patronymic')"
      :placeholder="$t('enter.patronymic')"
    />
    <mask-input
      :value="value.phone"
      @input="$emit('input', { ...value, phone: $event })"
      :rules="rules.phone"
      :title="$t('createOrder.phone')"
      placeholder="+8 800 500-75-97"
      :mask="['+# ### ###-##-##']"
      :tokens="phoneToken"
      type="tel"
    />
  </cart-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { vEmail, vLength, vName, vNumbers, vRequired, vSurname } from '@utils/validations'
import TextInput from '@elements/inputs/list/TextInput.vue'
import VTabs from '@layouts/VTabs.vue'
import SelectDropdown from '@dropdowns/list/SelectDropdown.vue'
import MaskInput from '@elements/inputs/list/MaskInput.vue'
import CartContainer from './CartContainer.vue'

export default Vue.extend({
  name: 'CartDelivery',
  components: { CartContainer, SelectDropdown, TextInput, MaskInput, VTabs },
  props: {
    isDelivery: Boolean,
    value: Object,
  },
  data() {
    return {
      deliveryPrice: 0,
      tabs: [
        { name: 'courier', value: this.$t('createOrder.courier'), disabled: false },
        { name: 'pick-up', value: this.$t('createOrder.pickUp'), disabled: true },
        { name: 'delivery service', value: this.$t('createOrder.deliveryService'), disabled: true },
      ],
      phoneToken: {
        '#': {
          pattern: /[0-9]/,
        },
      },
    }
  },
  computed: {
    ...mapGetters('Countries', ['countries']),
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Page', ['pageLoading']),
    rules() {
      return {
        country: [vRequired()],
        address: [vRequired()],
        email: [vRequired(), vEmail()],
        name: [vRequired(), vName(), vLength(2, 30)],
        surname: [vRequired(), vSurname(), vLength(2, 30)],
        patronymic: [vRequired(), vName(), vLength(2, 30)],
        phone: [vRequired(), vNumbers()],
      }
    },
  },
  methods: {
    ...mapActions('Countries', ['getCountries']),
  },
  async created() {
    if (!this.countries) {
      await this.getCountries()
    }
  },
})
</script>

<style lang="scss" module>
.title {
  margin-bottom: 6px;
  color: $secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
