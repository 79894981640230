// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartProducts_product_31xZM{padding:15px 0 0;border-top:1px solid #e5e3e3}", ""]);
// Exports
exports.locals = {
	"product": "CartProducts_product_31xZM"
};
module.exports = exports;
