// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartPromo_section_3Slen{display:grid;grid-auto-flow:column;grid-template-columns:1fr 1fr;-moz-column-gap:6px;column-gap:6px}", ""]);
// Exports
exports.locals = {
	"section": "CartPromo_section_3Slen"
};
module.exports = exports;
