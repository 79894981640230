<template>
  <cart-container :title="$t('orders.checkout')">
    <div
      v-for="({ name, value }, index) of filteredList"
      :key="`order-list-${index}`"
      :class="$style.item"
    >
      <span>{{ name }}</span>
      <span>{{ appCurrencySymbol() }}{{ value }}</span>
    </div>
    <v-button
      :class="$style.button"
      color="primary"
      size="xl"
      type="submit"
      :is-loading="pageLoading"
      :is-disabled="!isValid"
    >
      <icon name="shield" :class="$style.buttonIcon" />
      <span v-html="$t('createOrder.proceed')"></span>
    </v-button>
    <i18n :class="$style.text" path="createOrder.agreement" tag="p">
      <template #agreement>
        <router-link
          :to="
            Trans.i18nRoute({
              name: 'Documents',
              params: {
                slug: userAgreementDocId,
              },
            })
          "
          :class="[$style.text, $style.link]"
          >{{ $t('createOrder.userAgreement') }}
        </router-link>
      </template>
    </i18n>
  </cart-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Trans from '@plugins/i18n/translation'
import VButton from '@elements/VButton.vue'
import CartContainer from './CartContainer.vue'

export default Vue.extend({
  name: 'CartCheckout',
  components: { CartContainer, VButton },
  props: {
    isValid: Boolean,
    isDelivery: Boolean,
    fullPrice: Number,
    prices: Object,
  },
  data() {
    return {
      Trans,
    }
  },
  computed: {
    ...mapGetters('App', ['appCurrencySymbol']),

    ...mapGetters('Cart', ['cartProducts']),
    ...mapGetters('Countries', ['userAgreementDocId']),
    ...mapGetters('Page', ['pageLoading']),
    totalPrice() {
      return this.fullPrice - (this.prices.wrapping + this.prices.promo + this.prices.delivery)
    },
    list() {
      return [
        {
          name: this.$t('createOrder.yourOrder'),
          value: this.fullPrice,
        },
        {
          name: this.$t('createOrder.wrapping.title'),
          value: 0,
          hidden: !this.isDelivery,
        },
        {
          name: this.$t('createOrder.promo'),
          value: 0,
          hidden: !this.isDelivery,
        },
        {
          name: this.$t('delivery'),
          value: 0,
          hidden: !this.isDelivery,
        },
        {
          name: this.$t('createOrder.total'),
          value: this.totalPrice ?? 0,
        },
      ]
    },
    filteredList() {
      return this.list.filter(({ hidden }) => hidden !== true)
    },
  },
})
</script>

<style lang="scss" module>
.item {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border-top: 1px solid $secondary;
}

.button {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;

  &Icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    color: $secondary;
  }
}

.text {
  color: $secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  &.link {
    font-weight: 500;
    transition: opacity $transition;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
