<template>
  <cart-container :title="`${$t('createOrder.yourOrder')}`" :price="fullPrice">
    <cart-product
      v-for="product in cartProducts"
      :product="product"
      :key="product.id"
      :class="$style.product"
    />
  </cart-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import CartContainer from './CartContainer.vue'
import CartProduct from './CartProduct.vue'

export default Vue.extend({
  name: 'CartProducts',
  components: { CartContainer, CartProduct },
  props: { fullPrice: Number },
  computed: {
    ...mapGetters('Cart', ['cartProducts']),
  },
})
</script>

<style lang="scss" module>
.product {
  padding: 15px 0 0;
  border-top: 1px solid $secondary-light;
}
</style>
