// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartDelivery_title_1vmDp{margin-bottom:6px;color:var(--supster-secondary);font-weight:400;font-size:12px;line-height:18px}", ""]);
// Exports
exports.locals = {
	"title": "CartDelivery_title_1vmDp"
};
module.exports = exports;
