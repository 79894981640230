<template>
  <div :class="$style.section">
    <div :class="$style.header">
      <h2 :class="$style.title">{{ title }}</h2>
      <p v-if="price" :class="$style.price">
        <span v-if="sign">{{ sign }}</span>
        <span>{{ appCurrencySymbol() }}{{ price }}</span>
      </p>
    </div>
    <div :class="$style.container">
      <slot />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'CartContainer',
  props: {
    title: String,
    price: Number,
    sign: String,
  },
  computed: {
    ...mapGetters('App', ['appCurrencySymbol']),
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 20px;
  padding: 20px;
  box-shadow: inset 0 -1px 0 $secondary;

  &:last-child {
    box-shadow: none;
  }
}

.header {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.container {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 15px;
}

.title {
  color: $secondary;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.price {
  color: $secondary-medium;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
</style>
