// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartContainer_section_1lhLw{display:grid;grid-auto-flow:row;grid-gap:20px;padding:20px;box-shadow:inset 0 -1px 0 var(--supster-secondary)}.CartContainer_section_1lhLw:last-child{box-shadow:none}.CartContainer_header_3sRqA{display:grid;grid-auto-flow:column;justify-content:space-between}.CartContainer_container_38ops{display:grid;grid-auto-flow:row;grid-gap:15px}.CartContainer_title_30fFc{color:var(--supster-secondary);font-weight:500}.CartContainer_price_2PJf5,.CartContainer_title_30fFc{font-size:15px;line-height:22px}.CartContainer_price_2PJf5{color:#a09f9f;font-weight:400}", ""]);
// Exports
exports.locals = {
	"section": "CartContainer_section_1lhLw",
	"header": "CartContainer_header_3sRqA",
	"container": "CartContainer_container_38ops",
	"title": "CartContainer_title_30fFc",
	"price": "CartContainer_price_2PJf5"
};
module.exports = exports;
